import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permisoEscritura && !_vm.esRespuestaDeUsuario)?_c('div',[_c(VBtnToggle,[_c(VBtn,{attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){_vm.puntosLocal--;
          _vm.savePuntos();}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-minus")])],1),_c(VMenu,{attrs:{"bottom":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.puntosLocal))])])]}}],null,false,2169793662),model:{value:(_vm.showTextEditor),callback:function ($$v) {_vm.showTextEditor=$$v},expression:"showTextEditor"}},[_c(VCard,{staticClass:"pa-2 pt-4"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.savePuntos.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VTextField,{attrs:{"rules":_vm.puntosRules,"hint":"Ingresa cualquier número positivo o negativo","label":"Puntos extra/menos","dense":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.savePuntos();
                _vm.showTextEditor = false;}},model:{value:(_vm.puntosLocal),callback:function ($$v) {_vm.puntosLocal=$$v},expression:"puntosLocal"}}),_c(VBtn,{attrs:{"small":"","color":"primary","disabled":!_vm.isFormValid,"loading":_vm.loading},on:{"click":function($event){_vm.savePuntos();
                _vm.showTextEditor = false;}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("mdi-check")]),_c('span',[_vm._v("Guardar")])],1)],1)],1)],1),_c(VBtn,{attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){_vm.puntosLocal++;
          _vm.savePuntos();}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)],1):(_vm.esRespuestaDeUsuario && !_vm.permisoEscritura)?_c('div',[_c(VBtnToggle,[_c(VBtn,{attrs:{"small":""}},[_c('span',{staticStyle:{"margin-right":"2px"}},[_vm._v(_vm._s(_vm.puntosLocal))]),_vm._v(" Puntos ")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }