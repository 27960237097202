<template>
  <div>
    <div v-if="permisoEscritura && !esRespuestaDeUsuario">
      <v-btn-toggle>
        <v-btn
          small
          @click="
            puntosLocal--;
            savePuntos();
          "
          :disabled="loading"
        >
          <v-icon small>mdi-minus</v-icon>
        </v-btn>
        <v-menu v-model="showTextEditor" bottom :close-on-content-click="false">
          <template #activator="{ on, attrs }">
            <v-btn small v-on="on" v-bind="attrs">
              <span>{{ puntosLocal }}</span>
            </v-btn>
          </template>
          <v-card class="pa-2 pt-4">
            <v-form
              ref="form"
              v-model="isFormValid"
              lazy-validation
              @submit.prevent="savePuntos"
            >
              <v-text-field
                v-model="puntosLocal"
                :rules="puntosRules"
                hint="Ingresa cualquier número positivo o negativo"
                label="Puntos extra/menos"
                dense
                outlined
                @keyup.enter="
                  savePuntos();
                  showTextEditor = false;
                "
              ></v-text-field>
              <v-btn
                small
                color="primary"
                :disabled="!isFormValid"
                :loading="loading"
                @click="
                  savePuntos();
                  showTextEditor = false;
                "
              >
                <v-icon x-small class="mr-2">mdi-check</v-icon>
                <span>Guardar</span>
              </v-btn></v-form
            >
          </v-card>
        </v-menu>
        <v-btn
          small
          @click="
            puntosLocal++;
            savePuntos();
          "
          :disabled="loading"
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <div v-else-if="esRespuestaDeUsuario && !permisoEscritura">
      <v-btn-toggle>
        <v-btn small>
          <span style="margin-right: 2px;">{{ puntosLocal }}</span> Puntos
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import { modificarPuntosRespuesta } from "./comentarioRespuestas.service";

export default {
  props: {
    idRespuesta: {
      type: String,
      required: true,
    },
    idCurso: {
      type: String,
      required: true,
    },
    puntos: {
      type: Number,
      default: 0,
    },
    idUsuario: {
      type: String,
      required: true,
    },
    permisoEscritura: {
      type: Boolean,
      default: false,
    },
    esRespuestaDeUsuario: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      showTextEditor: false,
      isFormValid: true,
      puntosLocal: this.puntos,

      puntosRules: [
        (v) => !!v || "El campo es requerido",
        (v) => !isNaN(v) || "Ingresa un número válido",
      ],
    };
  },

  watch: {
    puntos(v) {
      this.puntosLocal = v;
    },
  },

  methods: {
    validateForm() {
      this.$refs.form.validate();
    },

    savePuntos() {
      this.loading = true;

      modificarPuntosRespuesta({
        idRespuesta: this.idRespuesta,
        idCurso: this.idCurso,
        puntos: this.puntosLocal,
      })
        .then((serverResponse) => {
          this.$validateResponse(serverResponse);
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
        })
        .catch(() => {
          this.$appErrorMessage();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
